<template>
    <div>
        <FormExames v-if="examesEmDia.length" v-model:funcionarios="funcionarios" />
        <Card class="mt-5 no-border no-padding">
            <template #title> Procedimentos a realizar </template>
            <template #content>
                <DataTable :value="funcionarios" dataKey="id">
                    <Column field="name" header="Funcionário" style="width: 40%"></Column>
                    <Column header="Procedimentos" style="width: 20%">
                        <template #body="slotProps">
                            <MultiSelectExame
                                :disabled="!slotProps.data.habilitarEdicao"
                                class="w-30rem"
                                v-model="slotProps.data.procedimentos"
                                @change="atualizarExamesDoFuncionario(slotProps.data)"
                                :class="{
                                    'invalid-field': slotProps.data.procedimentos.length === 0,
                                    'missing-clinical-exam': isFuncionarioEmFaltaComClinico(slotProps.data)
                                }"
                                :habilitaDialogObservacao="formData?.tipoExame?.id === enumAgendaTipoExame?.CONSULTA"
                            />
                        </template>
                    </Column>
                    <Column header="Habilitar edição" style="width: 10%">
                        <template #body="slotProps">
                            <div class="flex flex-row align-items-center justify-content-center">
                                <span class="mr-1">Não</span>
                                <InputSwitch v-model="slotProps.data.habilitarEdicao" />
                                <span class="ml-1">Sim</span>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </template>
            <template #footer>
                <div class="grid grid-nogutter justify-content-between">
                    <Button :loading="isLoading" class="w-2 p-button-outlined Examesstep4" label="Voltar" @click="prevPage()" />
                    <Button :loading="isLoading" class="w-2 Examesstep5" label="Avançar" @click="nextPage()" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import MultiSelectExame from '../../../exames/components/MultiSelectExame.vue';
import FormExames from './FormExames.vue';
import EnumTipoExame from '../../../../enums/TipoExame';
export default {
    components: {
        MultiSelectExame,
        FormExames
    },
    emits: ['prev-page', 'next-page'],
    props: {
        formData: Object,
        isLoading: Boolean
    },
    data() {
        return {
            submitted: false,
            habilitarEdicao: false,
            examesEmDia: false,
            funcionarios: [],
            enumAgendaTipoExame: EnumTipoExame
        };
    },
    mounted() {
        this.enumAgendaTipoExame = EnumTipoExame;
        const { funcionarios } = JSON.parse(JSON.stringify(this.formData));

        this.funcionarios = funcionarios.map((funcionario) => {
            const exames = funcionario.exames.map((exame) => {
                return { ...exame, aceitar: exame.procedimento.clinical || !exame.dataRefazerExame };
            });
            const examesPendentes = exames.filter((exame) => exame.aceitar);
            const procedimentos = examesPendentes.map((exame) => exame.procedimento);

            return { ...funcionario, exames, procedimentos };
        });

        this.examesEmDia = this.funcionarios.filter((funcionario) => {
            return funcionario.exames.some((exame) => exame.dataRefazerExame);
        });
    },
    methods: {
        isFuncionarioEmFaltaComClinico(funcionario) {
            return this.isExameOcupacional() && !funcionario.procedimentos.some((proc) => proc?.clinical);
        },
        atualizarExamesDoFuncionario(funcionario) {
            funcionario.exames.forEach((exame) => {
                const exameEstaSelecionado = funcionario.procedimentos.some((procedimento) => {
                    return procedimento.id === exame.procedimento.id;
                });

                exame.aceitar = exameEstaSelecionado;
            });
        },

        prevPage() {
            this.$emit('prev-page', { formData: { ...this.formData, funcionarios: this.funcionarios }, pageIndex: 2 });
        },
        async nextPage() {
            this.submitted = true;

            if (this.isExameOcupacional()) {
                if (this.validarSeNaoTemExameClinico()) {
                    this.$toast.add({ severity: 'error', summary: 'Para o Exame ocupacional, é necessário um procedimento clínico!', life: 4000 });
                    return;
                }
            }
            const procedimentosVazios = this.funcionarios.some((funcionario) => !funcionario?.procedimentos?.length);
            if (procedimentosVazios) {
                this.$toast.add({ severity: 'error', summary: 'Campos obrigatórios não preenchidos, favor verificar!', life: 3000 });
                return;
            }
            this.$emit('next-page', { formData: { ...this.formData, funcionarios: this.funcionarios }, pageIndex: 2 });
        },
        validarSeNaoTemExameClinico() {
            const examesClinicos = this.funcionarios.map((funcionario) => funcionario?.procedimentos.some((proc) => proc?.clinical));
            return examesClinicos.some((el) => el === false);
        },
        isExameOcupacional() {
            if (
                this.formData.tipoExame.id === EnumTipoExame.ADMISSIONAL ||
                this.formData.tipoExame.id === EnumTipoExame.PERIODICO ||
                this.formData.tipoExame.id === EnumTipoExame.RETORNO_AO_TRABALHO ||
                this.formData.tipoExame.id === EnumTipoExame.DEMISSIONAL ||
                this.formData.tipoExame.id === EnumTipoExame.MUDANCA_FUNCAO
            ) {
                return true;
            }
            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.p-card.no-border {
    box-shadow: unset;
}

.p-card.no-padding > :deep(.p-card-body) {
    padding: 0;
}

.invalid-field {
    border-color: red;
}

.missing-clinical-exam {
    border-color: red;
}
</style>
